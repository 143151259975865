import React from "react";
import classnames from "classnames";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import urlHead from "./backapi.js"
// core components
import PagesNavbar from "./NavBar.js";
// import Footer from "components/Footer/Footer.js";

class RegisterPage extends React.Component {
  constructor(props){
  super(props)
  this.state = {
    squares1to6: "",
    squares7and8: "",

    resUsername:"",
    resAlert : false,
    reSuccess: false,

    userName:"",
    firstName:"",
    lastName:"",
    email:"",
    passWord:"",
    repassWord:""

  }
  this.handleFormChange=this.handleFormChange.bind(this)
  };

  handleFormChange(event){
    this.setState({
      [event.target.name]:event.target.value
    })
  }

  componentDidMount() {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }
  followCursor = event => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6:
        "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)",
      squares7and8:
        "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    });
  };
  render() {
    if (this.state.resAlert){
      if (this.state.reSuccess)
      {
          alert = <Alert color = "success"> account created successfully, please login with your username {this.state.resUsername} </Alert>
      }
      else
      {
          alert = <Alert color = "danger"> account fail to create, check the error:{this.state.resUsername} </Alert>
      }
    }
    else{
      alert = <Alert color = "info"> Welcome, please register before login to the dashboard </Alert>
    }
    return (
      <>
        <PagesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <div className="page-header-image" />
            <div className="content">
              <Container>
                <Row>
                  <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                    <div
                      className="square square-7"
                      id="square7"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <div
                      className="square square-8"
                      id="square8"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <Card className="card-register">
                      <CardHeader>
                        <CardImg
                          alt="..."
                          src={require("assets/img/square-purple-1.png")}
                        />
                        <CardTitle tag="h4">Register</CardTitle>
                      </CardHeader>
                      <CardBody>
                        {alert}
                        <Form className="form">
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.userNameFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.userName}
                              name = "userName"
                              placeholder="Username"
                              type="text"
                              onChange={this.handleFormChange}
                              onFocus={e =>
                                this.setState({ userNameFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ userNameFocus: false })
                              }
                            />

                          </InputGroup>

                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.firstNameFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-badge" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.firstName}
                              name = "firstName"
                              placeholder="First Name"
                              type="text"
                              onChange={this.handleFormChange}
                              onFocus={e =>
                                this.setState({ firstNameFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ firstNameFocus: false })
                              }
                            />
                          </InputGroup>


                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.lastNameFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-badge" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.lastName}
                              name = "lastName"
                              onChange={this.handleFormChange}
                              placeholder="Last Name"
                              type="text"
                              onFocus={e =>
                                this.setState({ lastNameFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ lastNameFocus: false })
                              }
                            />
                          </InputGroup>


                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.emailFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-email-85" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.email}
                              name = "email"
                              onChange={this.handleFormChange}
                              placeholder="Email"
                              type="text"
                              onFocus={e => this.setState({ emailFocus: true })}
                              onBlur={e => this.setState({ emailFocus: false })}
                            />
                          </InputGroup>
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.passwordFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-lock-circle" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.passWord}
                              name = "passWord"
                              onChange={this.handleFormChange}
                              placeholder="Password"
                              type="password"
                              onFocus={e =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ passwordFocus: false })
                              }
                            />
                          </InputGroup>

                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.repasswordFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-lock-circle" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.repassWord}
                              name = "repassWord"
                              onChange={this.handleFormChange}
                              placeholder="Re-enter Password"
                              type="password"
                              onFocus={e =>
                                this.setState({ repasswordFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ repasswordFocus: false })
                              }
                            />
                          </InputGroup>


                          <FormGroup check className="text-left">
                            <Label check>
                              <Input type="checkbox" />
                              <span className="form-check-sign" />I agree to the{" "}
                              <a
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                terms and conditions
                              </a>
                              .
                            </Label>
                          </FormGroup>
                        </Form>
                      </CardBody>
                      <CardFooter>
                        <Button
                        onClick = {() =>
                          {
                          console.log(
                          "\n",this.state.userName,
                          "\n",this.state.firstName,
                          "\n",this.state.lastName,
                          "\n",this.state.email,
                          "\n",this.state.passWord,
                          "\n",this.state.repassWord
                        )
                        var targetUrl = urlHead+"users/"
                        fetch(targetUrl,
                        {
                          method:'POST',
                          headers:{'Content-Type':'application/json','Accept': 'application/json'},
                          body: JSON.stringify({
                            username:this.state.userName,
                            first_name:this.state.firstName,
                            last_name:this.state.lastName,
                            email:this.state.email,
                            password:this.state.passWord,
                            re_password:this.state.repassWord
                          })
                        })
                        .then(response=>response.json())
                        .then(responseJSON=>{
                          if ("id" in responseJSON)
                          { this.setState({reSuccess:true})
                            this.setState({resAlert:true})
                            this.setState({resUsername:responseJSON["username"]})
                            console.log(this.state.resAlert," ",this.state.resUsername)
                          }

                          else{
                            this.setState({reSuccess:false})
                            this.setState({resAlert:true})
                            var displayMsg = ""
                            for (let item in responseJSON){
                              displayMsg = displayMsg+responseJSON[item]
                            }
                            this.setState({resUsername:displayMsg})
                            console.log(this.state.resAlert," ",this.state.resUsername)
                          }
                        })

                      }

                      }
                        className="btn-round"
                        color="primary"
                        size="lg">
                          Get Started

                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
                <div className="register-bg" />
                <div
                  className="square square-1"
                  id="square1"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-2"
                  id="square2"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-3"
                  id="square3"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-4"
                  id="square4"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-5"
                  id="square5"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-6"
                  id="square6"
                  style={{ transform: this.state.squares1to6 }}
                />
              </Container>
            </div>
          </div>

        </div>
      </>
    );
  }
}

export default RegisterPage;
