import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/css/nucleo-icons.css";
import "./assets/scss/blk-design-system-react.scss?v=1.1.0";
import "./assets/demo/demo.css";
import Index from "./pack.js";
import RegisterPage from "./components/registerPage.js"
import LoginPage from "./components/loginPage.js"
import LandingPage from "./Landing.js"
import App from "./App.js"
import * as allinone from "./redux/AllinOne.js"
import {Provider} from "react-redux"
import * as serviceWorker from './serviceWorker';

//<Route path="/home" render={props=><Index {...props}/>}/>

ReactDOM.render(
  <BrowserRouter>

  <Provider store = {allinone.store}  >
    <App />
  </Provider>

  </BrowserRouter>
  ,
  document.getElementById('root')
);
// var myNewP = document.createElement("p")
// myNewP.innerHTML = "This is some serious stuff"
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// ReactDOM.render(
//   <NavBar />,
//   document.getElementById('root')
// );

// serviceWorker.unregister();
