import React from "react";
import classnames from "classnames";
// reactstrap components
import DataTable from "./dashTable.js"
import ModalForm from "./Modal.js"
import PerfectScrollbar from "perfect-scrollbar";
import ReactDatetime from "react-datetime"
import DashNavbar from "./DBar.js"
import UserAuth from "./userAuth.js"
//import { ScrollView } from 'react-native';
import '../App.css'
import { connect } from 'react-redux'
import * as allinone from '../redux/AllinOne.js'
import urlHead from "./backapi.js"
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Table,
  TabPane,
  UncontrolledTooltip

} from "reactstrap";
import {Redirect} from "react-router-dom"
// var ps;



class DashBoard extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      items:[],
      users:[],
      auto: false,
      input_date:'',
      flag : true

    }

  }

   async filterUsers(){
    var arr = this.state.users
    var u = []
    for (var idx = 0; idx<arr.length;idx++){
      u.push(arr[idx].name)
    }
  //  console.log("USERS ONLYYYYYYY:     ",u)
    var d = ''
    if (this.state.input_date!=''){
      try{
        d =  this.state.input_date.toArray()

        if (d[0]==0)
          {
            return //future alert flag if necessary
          }

      }
      catch(err){
        console.log("DATE ARRAY ERROR",err)
        return
      }
    }
    else{
      return //future alert flag if necessary
    }
    console.log(d,"<------ this is d variable",this.state.input_date)
    d[1] = d[1]+1
    var up_users = []
    console.log(d[0],d[1],d[2],"yyyy mm dd")
    var rec_date = d[0].toString()+'-'+d[1].toString()+'-'+d[2].toString()
    var er = 0
    var tot = 0
    for (var idx = 0; idx<arr.length;idx++){
      var p_creator = 'author='+u[idx]
      var p_d = 'record_date='+rec_date
      var desiUrl = urlHead+'api/effouser/auto/?'+p_d+'&'+p_creator
    //  console.log(desiUrl,"<<-----URL that I fetch during filter")
      await fetch(desiUrl,
      {
        method:'GET',
        headers:
        {
          'Content-Type':'application/json',
          'Accept': 'application/json',
          'Authorization':'Token '+localStorage.authToken
        }
      })
      .then(response=>response.json())
      .then(responseJSON=>{
        //console.log(responseJSON)
        if (responseJSON["status"]=="valid"){
          up_users.push([u[idx],responseJSON[u[idx]]]);
          tot = tot + responseJSON[u[idx]];
        }
        else if (responseJSON["status"]=="nothing"){
          up_users.push([u[idx],0])

        }
        else{
          er = 1;
          }

      }).catch(e=>
        {console.log("error on filtering: ",e)
         er = 1
          })
    }
    if (er == 1){
      //probably some alert flag
      return
    }
    else{
      var output = []

      for (var idx = 0; idx<up_users.length;idx++){
        if (tot == 0){
        output.push({name:up_users[idx][0],pts:"0.0"})}
        else{
          output.push({name:up_users[idx][0],pts:(100*up_users[idx][1]/tot).toFixed(1)})
        }
      this.setState({users:output})
      }
    }
    console.log(this.state.users,"AFTER THE RUNNING THE FUNCTION")


  }

  async getUsers(){
    var arr = []
    await fetch(urlHead+'api/effouser/auto/?hist=1',
      {
        mathod:"GET",
        headers:
        {
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization':'Token '+localStorage.authToken
        }

      })
      .then(response=>response.json())
      .then(responseJSON=>{
        if (responseJSON["status"] === "valid"){
          var tot = 0
          for (let key in responseJSON){
            if (key != "status"){
              tot = tot + responseJSON[key]

            }
          }
          for (let key in responseJSON){
            if (key!= "status"){
              if (tot == 0){
                arr.push({name:key,pts:"0.0"})
              }
              else{arr.push({name:key,pts:(100*responseJSON[key]/tot).toFixed(1)})}
            }
          }
          this.setState({users:arr})

        }
      }).catch(err=>console.log(err))



  }

  async getItems(){
      await fetch(urlHead+'api/vieweffo/',
      {
        method:'GET',
        headers:
        {
          'Content-Type':'application/json',
          'Accept': '*/*',
          'Authorization':'Token '+localStorage.authToken
        }
      }

    )
      .then(response=>response.json())
      .then(responseJSON=>{
        this.setState({items:responseJSON})
      })
      .catch(err=>console.log(err))
  }

  addItemtoState = (item)=>{
    this.setState(prevState=>({items:[...prevState.items,item]}))
  }

  updateState = (item) =>{
    const itemIdx = this.state.items.findIndex(data => data.eff_id === item.eff_id)
    const newArr = [
      ...this.state.items.slice(0,itemIdx),
      item,
      ...this.state.items.slice(itemIdx+1)
    ]
    this.setState({items:newArr})

  }

  deleteItemfromState = (id) =>{
    const updatedItems = this.state.items.filter(item=>item.eff_id !== id)
    this.setState({items:updatedItems})
  }
  handleDateTimePicker = (moment, name) => this.setState({ [name]: moment });


  async componentDidMount(){
  //   if (navigator.platform.indexOf("Win") > -1) {
  //   ps = new PerfectScrollbar(document.getElementsByClassName("rt-table")[0]);
  // }
    var stuff = await UserAuth.isAuthenticated()
    this.setState({flag:stuff})
    this.getItems()
    this.getUsers()


  }
onDm = ()=>{
  this.props.erClose({trigState:false,sucState:false,infoState:''})
}

  render(){
    const {trigState,sucState,infoState} = this.props
    var status = false
    var usersTemp
    if (this.state.flag == false ){
       status = false
    }
    else{
      status = true
    try{


      usersTemp = this.state.users.map(indivi=>{
        return (

          <Col  xl="3">
          <div className="mb-3">
                  <small className="text-uppercase font-weight-bold">
                  <i className="tim-icons icon-single-02" /> {indivi.name}
                  </small>
                </div>
          <Card>

          <CardBody>
          <br />
            <h3 className="text-center"><p className="title">{indivi.pts} %</p></h3>
          <br />
          </CardBody>

          </Card>
          </Col>

        )
      })}
    catch(e){
      status = false
      console.log("Permission error alert ",e)
    }
    }
    const currUsers = usersTemp
    const quote = status







    return(



<>
  {  !quote &&
  <Redirect push to = "/login" />
  }
        <DashNavbar/>
      <div style={{maxHeight:'1000px',overflowY:'auto'}}>

      <Container>

      <div className="title">
            <h1 className="title" style={{margin: "20px 0"}}>
            <i className="tim-icons icon-chart-bar-32" /> 总分统计{' '}



            </h1>
          <Container>
          <Row >
          <Col md="2">
            <div className="datepicker-container">
            <FormGroup>
                <ReactDatetime
                  closeOnSelect = {true}
                  value = {this.state.input_date}
                  name="input_date"
                  timeFormat = {false}
                  className = "body"

                  inputProps={{
                    className: "form-control",
                    placeholder: "Select Filter Date",

                  }}
                  onChange = {moment => this.handleDateTimePicker(moment, 'input_date')}


                />
              </FormGroup>
              </div>
              </Col>


              <Col>
              <div>
              <Button
              id = "del_ddate"
              className = "btn-round btn-icon"
              color = "neutral"
              size = "sm"
              onClick={()=>{
                //console.log(this.state.input_date.toArray())
                // console.log(this.state.input_date.years," ",
                //   this.state.input_date.months," ",
                //   this.state.input_date.date)
                this.filterUsers()

              }}
              >
              <i className="tim-icons icon-scissors"/>
              </Button>
              <UncontrolledTooltip placement="bottom" target="del_ddate">
                查看特定日期后的分红概况
              </UncontrolledTooltip>

              </div>





              </Col>
              <Col>

              {trigState &&
                <Alert

              color={sucState?"success":"danger"}
              toggle = {this.onDm}
              >
              {infoState}
              </Alert>}
              </Col>

          </Row>
          </Container>

          </div>




      <Row className="justify-content-md-center">


        {currUsers}







      </Row>
      </Container>



    <Container >
      <Row>
        <Col>
          <h4 className="title" style={{margin: "20px 0"}}><i className="tim-icons icon-notes" /> 打卡专用{'  '}
          <Button

          color = "warning"
          size = "sm"
          onClick={
            async()=>{


              var arr = []

                await fetch(urlHead+'api/effouser/auto/',{
                  method:"GET",
                  headers:
                  {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Authorization':'Token '+localStorage.authToken
                  }
                })
                .then(response=>response.json())
                .then(responseJSON=>{
                  if (responseJSON["status"] === "valid"){
                    var tot = 0
                    for (let key in responseJSON){
                      if (key != "status"){
                        tot = tot + responseJSON[key]

                      }
                    }
                    for (let key in responseJSON){
                      if (key!= "status"){
                        arr.push({name:key,pts:(100*responseJSON[key]/tot).toFixed(1)})
                      }
                    }
                    this.setState({users:arr})

                  }}).catch(err=>console.log(err))

            }
          }
          >
          AUTO
          </Button>
          </h4>

        </Col>


      </Row>
      <Row>
        <Col>
          <DataTable
            items={this.state.items}
            updateState={this.updateState}
            deleteItemfromState={this.deleteItemfromState} />
        </Col>
      </Row>

      <Row>
        <Col>
          <ModalForm
          buttonLabel="Add Item"
          addItemtoState={this.addItemtoState}/>

        </Col>
      </Row>
    </Container>

    </div>

</>


    )

  }



}

function mapStateToProps(state){

  return {
    trigState:state.msg.trigState,
    sucState:state.msg.sucState,
    infoState:state.msg.infoState,
  }
}

function mapDispatchToProps(dispatch){
  return {
    erTrue: (message)=>dispatch(allinone.erTrue(message)),
    erFalse: (message)=>dispatch(allinone.erFalse(message)),
    erClose: (message)=>dispatch(allinone.erClose(message)),
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(DashBoard)
