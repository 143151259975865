import React from "react";

// core components
import SucNavbar from "./components/SBar.js";
import PageHeader from "./components/headers.js"
import App from './App';

// sections for this page/view
import UserAuth from "./components/userAuth.js"
import {Redirect} from "react-router-dom"

class Lsuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      flag: true
    };
  }
 // async componentDidUpdate(){
 //   var targetUrl = "http://localhost:8000/users/me/"
 //
 //  await  fetch(targetUrl,
 //   {
 //     method:'GET',
 //     headers:{
 //       'Content-Type':'application/json',
 //       'Accept': 'application/json',
 //       'Authorization': 'Token '+localStorage.authToken
 //     }
 //
 //   })
 //   .then(response=>response.json())
 //   .then(responseJSON=>{
 // //    console.log("jimmy stupid again")
 //     if ("id" in responseJSON && (responseJSON["username"]==localStorage.username))
 //     {
 //
 //       this.setState({flag:true})
 //       console.log(responseJSON["username"],"successful verified jimmy")
 //
 //       localStorage.setItem('loginStatus',"true")
 //
 //       console.log("MAIN TRUE",this.state.flag)
 //       // for (let key in localStorage){
 //       //   console.log(key," in current local",localStorage[key])
 //       // }
 //
 //     }
 //     else if ("detail" in responseJSON){
 //       localStorage.setItem('loginStatus',"false")
 //       this.setState({flag:false})
 //       console.log("MAIN false",this.state.flag)
 //
 //     }
 //     else{
 //       localStorage.setItem('loginStatus',"false")
 //       console.log(localStorage.getItem('username'))
 //       console.log("MAIN false",this.state.flag)
 //       this.setState({flag:false})
 //
 //     }
 //   })
 //
 // }
 async componentDidMount() {
    document.body.classList.toggle("index-page");

    var stuff = await UserAuth.isAuthenticated()
    this.setState({flag:stuff})
    console.log("COMPONENT DID MOUNT: ", "FROM WEB API",stuff)
  }
  componentWillUnmount() {
    document.body.classList.toggle("index-page");
  }
  render() {
    var status = false
    if (this.state.flag == false ){
       status = false
    }
    else{
      status = true
    }
    const quote = status


    console.log(quote,": QUOOOOOOOOTTTTTEEEEE")


    return (
      <>
      {console.log(quote,"BEFORE RENDERING REDIRECT")}
      {  !quote &&
        <Redirect push to = "/login" />
      }
        <SucNavbar />
        <div className="wrapper">
        <PageHeader />



        </div>
      </>
    );
  }
}

export default Lsuccess;
