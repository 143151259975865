import React from "react";
import classnames from "classnames";
import { Link,withRouter } from "react-router-dom";
// reactstrap components
import auth from "./auth.js"
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import urlHead from "./backapi.js"
// core components
import PagesNavbar from "./NavBar.js";
// import Footer from "components/Footer/Footer.js";

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      userName:"",
      passWord:"",
      alertTrig:false,
      reSuccess:false,
      erMsg:"",
      squares1to6: "",
      squares7and8: ""
    }
    this.handleFormChange=this.handleFormChange.bind(this)
  }


  handleFormChange(event){
    this.setState({
      [event.target.name]:event.target.value
    })
  }
  componentDidMount() {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", this.followCursor);
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
    document.documentElement.removeEventListener(
      "mousemove",
      this.followCursor
    );
  }
  followCursor = event => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    this.setState({
      squares1to6:
        "perspective(500px) rotateY(" +
        posX * 0.05 +
        "deg) rotateX(" +
        posY * -0.05 +
        "deg)",
      squares7and8:
        "perspective(500px) rotateY(" +
        posX * 0.02 +
        "deg) rotateX(" +
        posY * -0.02 +
        "deg)"
    });
  };
  render() {
    return (
      <>
        <PagesNavbar />
        <div className="wrapper">
          <div className="page-header">
            <div className="page-header-image" />
            <div className="content">
              <Container>
                <Row>
                  <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                    <div
                      className="square square-7"
                      id="square7"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <div
                      className="square square-8"
                      id="square8"
                      style={{ transform: this.state.squares7and8 }}
                    />
                    <Card className="card-register">
                      <CardHeader>
                        <CardImg
                          alt="..."
                          src={require("assets/img/square-purple-1.png")}
                        />
                        <CardTitle tag="h4">LOGIN</CardTitle>
                      </CardHeader>
                      <CardBody>
                      {this.state.alertTrig && <Alert color = "danger">
                      Login Failed, please check the error:{this.state.erMsg}
                      </Alert>}
                        <Form className="form">
                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.userNameFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-single-02" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.userName}
                              name = "userName"
                              placeholder="Username"
                              type="text"
                              onChange ={this.handleFormChange}
                              onFocus={e =>
                                this.setState({ userNameFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ userNameFocus: false })
                              }
                            />
                          </InputGroup>

                          <InputGroup
                            className={classnames({
                              "input-group-focus": this.state.passwordFocus
                            })}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="tim-icons icon-lock-circle" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              value = {this.state.passWord}
                              name = "passWord"
                              onChange = {this.handleFormChange}
                              placeholder="Password"
                              type="password"
                              onFocus={e =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={e =>
                                this.setState({ passwordFocus: false })
                              }
                            />
                          </InputGroup>

                              <Button
                              className="btn-round"
                              color="success"
                              size ="sm"
                              onClick=
                              {
                                ()=>{
                                  "location.href = '/register-page'"
                                    auth.login(()=>{
                                    this.props.history.push("/register-page"); });
                                  } }
                              >
                              <i className="tim-icons icon-badge" /> New? Register
                              </Button>


                        </Form>
                      </CardBody>
                      <CardFooter>
                        <Button className="btn-round" color="primary" size="lg"
                        onClick =
                        {
                          ()=>{
                            console.log(
                              "\n",this.state.userName,
                              "\n",this.state.passWord
                            )
                            var targetUrl = urlHead+"token/login/"
                            fetch(targetUrl,
                            {
                              method:'POST',
                              headers:{'Content-Type':'application/json','Accept': 'application/json'},
                              body: JSON.stringify({
                                username:this.state.userName,
                                password:this.state.passWord
                              })
                            })
                            .then(response=>response.json())
                            .then(responseJSON=>{
                              if ("auth_token" in responseJSON)
                              { localStorage.setItem('authToken',responseJSON["auth_token"])
                                localStorage.setItem('username',this.state.userName)
                                localStorage.setItem('loginStatus',"true")

                                console.log(this.state.userName," ",responseJSON["auth_token"])
                                this.setState({reSuccess:true})
                                this.setState({alertTrig:false})
                                this.props.history.push("/welcome")
                              }

                              else{
                                this.setState({reSuccess:false})
                                this.setState({alertTrig:true})
                                var displayMsg = ""
                                for (let item in responseJSON){
                                  displayMsg = displayMsg+responseJSON[item]
                                }
                                this.setState({erMsg:displayMsg})
                                console.log(this.state.erMsg)
                              }
                            })





                          }
                        }

                        >
                          <i className="tim-icons icon-tap-02" /> Log In
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
                <div className="register-bg" />
                <div
                  className="square square-1"
                  id="square1"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-2"
                  id="square2"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-3"
                  id="square3"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-4"
                  id="square4"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-5"
                  id="square5"
                  style={{ transform: this.state.squares1to6 }}
                />
                <div
                  className="square square-6"
                  id="square6"
                  style={{ transform: this.state.squares1to6 }}
                />
              </Container>
            </div>
          </div>

        </div>
      </>
    );
  }
}

export default withRouter(LoginPage);
