import React from "react";
import { Link,withRouter } from "react-router-dom";
import auth from "./auth.js"
import UserAuth from "./userAuth.js"
// reactstrap components
import {
  Alert,
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Label
} from "reactstrap";

import ReactDatetime from "react-datetime"
import { connect } from 'react-redux'
import * as allinone from "../redux/AllinOne.js"
import urlHead from "./backapi.js"

class DashNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      flag:UserAuth.isAuthenticated(),
      d_date:'',
      l_date:''
    };
  }
  async latest_ddate(){
    await fetch(urlHead+"api/ddate/latest/",{
      method:'GET',
      headers:
      {
        'Content-Type':'application/json',
        'Accept': 'application/json',
        'Authorization':'Token '+localStorage.authToken
      }
    }).then(response=>response.json())
    .then(responseJSON=>{
      if ("status" in responseJSON){
        //empty ddate
        this.setState({l_date:''})
      }
      else if ("detail" in responseJSON){
        //error erMsg alert

      }
      else if ("latest" in responseJSON){
        //show case the latest d_date
        this.setState({l_date:responseJSON["latest"]})
      }
    }).catch(er=>console.log(er))
  }
  handleDateTimePicker = (moment, name) => this.setState({ [name]: moment });
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
    this.latest_ddate()

  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }

  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-info"
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };

  render() {



    // for (let key in localStorage){
    //   console.log(key," in local",localStorage[key])
    // }

    return (



      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >


        <Container>

          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">

              <Row>
                <Col className="collapse-brand" xs="6">
                  <a>
                    欧皇工会
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav navbar>




              <NavItem>


                <Button
                className="nav-link d-none d-lg-block"
                color = "success"
                onClick={
                  async ()=>{
                    var flag
                    flag = false
                    if (localStorage.authToken)
                    {
                      var targetUrl = urlHead+"users/me/"

                      await fetch(targetUrl,
                      {
                        method:'GET',
                        headers:{
                          'Content-Type':'application/json',
                          'Accept': 'application/json',
                          'Authorization': 'Token '+localStorage.authToken
                        }

                      })
                      .then(response=>response.json())
                      .then(responseJSON=>{

                        if ("id" in responseJSON && (responseJSON["username"]==localStorage.username))
                        {

                          flag = true
                          console.log(responseJSON["username"],"successful verified jimmy")

                          localStorage.setItem('loginStatus',"true")
                          console.log("record store successfully")
                          for (let key in localStorage){
                            console.log(key," in current local",localStorage[key])
                          }
                        }
                        else if ("detail" in responseJSON){
                          localStorage.setItem('loginStatus',"false")
                          flag = false
                        }
                        else{
                          localStorage.setItem('loginStatus',"false")
                          console.log(localStorage.getItem('username'))

                          flag = false
                        }
                      })
                    }
                    else
                    {
                      flag = false
                    }

                    if (flag)//localStorage.loginStatus && localStorage.loginStatus=="true")
                    {
                      auth.login(()=>{
                        this.props.history.push("/profile");});
                    }
                    else
                    {
                      auth.login(()=>{
                        this.props.history.push("/login");});
                    }

                }
              }
                >
                  <i className="tim-icons icon-single-02" />
                  Profile
                </Button>


              </NavItem>

              <NavItem>
                <Button
                id = "l_date"
                className="btn-simple btn-round"
              color="primary"

              type="button"

              >
                {this.state.l_date==''?"No Dividend Date":this.state.l_date}
                </Button>
                <UncontrolledTooltip placement="bottom" target="l_date">
                  最新分红日期
                </UncontrolledTooltip>

                <Button
                  size = "sm"
                  color="neutral"
                  onClick = {async ()=>{
                    if (this.state.l_date == ''){
                      this.props.erFalse({trigState:true,sucState:false,infoState:"Error: No date to delete"})
                      return //this can show alert value not existed
                    }
                    else {
                      var u = false
                      if (localStorage.authToken)
                      {
                        var targetUrl = urlHead+"users/me/"
                        await fetch(targetUrl,
                        {
                          method:'GET',
                          headers:{
                            'Content-Type':'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Token '+localStorage.authToken
                          }

                        })
                        .then(response=>response.json())
                        .then(responseJSON=>{
                          if (responseJSON["username"] == 'GAN'){
                            u = true
                          }
                        }).catch(er=>console.log("Err when fetching username",er))
                      }
                      else {u = false // permission not allowed
                      }

                      if (u == true){
                        var d_id = 0
                        var tempMsg = ''
                        await fetch(urlHead+"api/ddate/latest/",{
                          method:'GET',
                          headers:
                          {
                            'Content-Type':'application/json',
                            'Accept': 'application/json',
                            'Authorization':'Token '+localStorage.authToken
                          }
                        }).then(response=>response.json())
                        .then(responseJSON=>{
                          if ("status" in responseJSON){
                            //empty ddate
                            this.setState({l_date:''})
                            tempMsg = "Error: date deleted unsuccessful"

                          }
                          else if ("detail" in responseJSON){
                            //error erMsg alert
                            this.setState({l_date:''})
                           tempMsg = responseJSON["detail"]

                          }
                          else if ("latest" in responseJSON){
                            //show case the latest d_date
                            d_id = responseJSON['d_id']

                          }
                        }).catch(er=>console.log(er))
                        if (d_id == 0){
                          this.props.erFalse({trigState:true,sucState:false,infoState:tempMsg})
                          return // error alert
                        }
                        else{

                          var indicator = false
                          await fetch(urlHead+"api/ddate/"+d_id.toString()+"/",{
                            method:'DELETE',
                            headers:
                            {
                              'Content-Type':'application/json',
                              'Accept': 'application/json',
                              'Authorization':'Token '+localStorage.authToken
                            }
                          }).then(response=>response.json())
                          .then(responseJSON=>{
                            if ("status" in responseJSON){
                              if (responseJSON["status"]=="set"){
                                indicator = true

                              }
                              else{
                                indicator = false

                              }
                            }

                          }).catch(e=>{
                            console.log("Err occured when attempting to delete latest d date")
                            indicator = false
                          })
                        }
                        if (indicator == false){
                          this.props.erFalse({trigState:true,sucState:false,
                            infoState:'Unknown Error occurs in BackEnd'})

                          return //error alert should be trigger
                        }
                        else{
                          this.props.erTrue({trigState:true,sucState:false,
                            infoState:"successfully deleted latest dividend date"})
                          this.latest_ddate()
                        }
                      }
                      else{
                        //some sort of alert permission not allowed
                        var tempMsg = "Permission Not Allowed"
                        this.props.erFalse({trigState:true,sucState:false,infoState:tempMsg+' '})
                      }

                    }

                }}
                ><i className="tim-icons icon-trash-simple" />Del
                </Button>


                <Button

                color = "#212529"
                size = "sm"
                onClick = {async ()=>{
                  var u = false
                  if (localStorage.authToken)
                  {
                    var targetUrl = urlHead+"users/me/"
                    await fetch(targetUrl,
                    {
                      method:'GET',
                      headers:{
                        'Content-Type':'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Token '+localStorage.authToken
                      }

                    })
                    .then(response=>response.json())
                    .then(responseJSON=>{
                      if (responseJSON["username"] == 'GAN'){
                        u = true
                        console.log("IT IS IN FACE USERNAME GAN ")
                      }
                    }).catch(er=>console.log("Err when fetching username",er))
                  }
                  else {u = false // permission not allowed alert
                    return
                  }
                  if (u == false){
                    var tempMsg = "Permission not allowed"
                    console.log("----PERMISSION PROBLEM, MARKER FOR REDUX STORE----")
                    this.props.erFalse({trigState:true,sucState:false,infoState:tempMsg})

                    return
                  }

                  var em = false
                  var currInput =[]
                  ///////// checking latest date exist or not //////////
                  if (this.state.l_date == ''){
                      console.log("EMPTY D DATE")
                      em = true
                  }
                  else {
                    em = false
                  }
                  ///////// checking latest date exist or not //////////

                  ////// checking input  ///////////
                  try{
                      currInput = this.state.d_date.toArray() //check input date

                  }
                  catch(err){
                    console.log("Error on input ddate to add: ",err)
                    // alert on error; empty input
                    this.props.erFalse({trigState:true,sucState:false,infoState:'Error: Empty Input'})

                    return

                  }
                  ////// checking input  ///////////////

                  var add_permit = false
                  if (em == false){
                    var temp_date = this.state.l_date
                    var b = temp_date.split`-`.map(x=>+x)
                    var inState = new Date(b[0],b[1]-1,b[2])
                    var inputState = new Date(currInput[0],currInput[1],currInput[2])
                    if (inputState>inState){
                      add_permit = true
                    }
                    else{
                      // input error alert as it is smaller than already input
                      this.props.erFalse({trigState:true,sucState:false,infoState:"Error: Input needs to be later than current one"})
                      console.log("TOO SMALL, NEED BIGGER INPUT DDATE SKURR")
                      return
                    }
                  }

                  else{
                    add_permit = true
                  }

                  if (add_permit == false){
                    this.props.erFalse({trigState:true,sucState:false,infoState:'Unkown Error occured in BackEnd'})
                    console.log("UNKNOWN ERROR BEFORE ADD")
                    return
                  }
                  else{

                    console.log(currInput[0].toString()+'-'+(currInput[1]+1).toString()+'-'
                    +currInput[2].toString(),"TESTING BEFORE POST")
                    var indicator
                    await fetch(urlHead+"api/ddate/",{
                      method:'POST',
                      headers:
                      {
                        'Content-Type':'application/json',
                        'Accept': '*/*',
                        'Authorization':'Token '+localStorage.authToken
                      },
                      body:JSON.stringify(
                      {
                        d_date:currInput[0].toString()+'-'+(currInput[1]+1).toString()+'-'
                        +currInput[2].toString()
                      })
                    }).then(response=>response.json())
                    .then(responseJSON=>{
                      console.log(responseJSON)
                      if ("status" in responseJSON){
                        if (responseJSON["status"]=="set"){
                          indicator = true
                        }
                        else{indicator = false}
                      }

                    }).catch(er=>{console.log("POST DDATE ERROR: ",er)
                          indicator = false})

                    if (indicator == true){

                      this.latest_ddate()
                      this.props.erTrue({trigState:true,sucState:false,infoState:'Successfully added dividend date'})
                    }
                    else{
                      this.props.erFalse({trigState:true,sucState:false,infoState:'Error occurs when posting date'})
                      return
                    }
                  }



                }}
                >
                <i className="tim-icons icon-simple-add"/>Add
                </Button>

              </NavItem>



              <NavItem>

              <div className="datepicker-container">

              <FormGroup>
                  <ReactDatetime
                    closeOnSelect = {true}
                    value = {this.state.d_date}
                    name="d_date"
                    timeFormat = {false}
                    className = "body"

                    inputProps={{
                      className: "form-control",
                      placeholder: "select dividend date",

                    }}
                    onChange = {moment => this.handleDateTimePicker(moment, 'd_date')}


                  />

                </FormGroup>


                </div>




              </NavItem>



            </Nav>

          </Collapse>
        </Container>

      </Navbar>


    );
  }
}
function mapDispatchToProps(dispatch){
  return {
    erTrue: (message)=>dispatch(allinone.erTrue(message)),
    erFalse: (message)=>dispatch(allinone.erFalse(message)),
    erClose: (message)=>dispatch(allinone.erClose(message)),
  }
}
export default connect(null,mapDispatchToProps)(withRouter(DashNavbar));
