



class Auth{
  constructor() {
    this.authenticated = false;
  }
  login(cb) {
    this.authenticated = true;
    cb();
  }

  logout(cb){
    this.authenticated = false;
    cb();
  }

  isAuthenticated(){
    // if (localStorage.authToken && localStorage.username)
    // { var flag = false
    //   var targetUrl = "http://localhost:8000/users/me/"
    //   fetch(targetUrl,
    //   {
    //     method:'GET',
    //     headers:{
    //       'Content-Type':'application/json',
    //       'Accept': 'application/json'
    //       'Authorization': 'Token '+localStorage.authToken
    //     }
    //
    //   })
    //   .then(response=>response.json())
    //   .then(responseJSON=>{
    //     if ("id" in responseJSON && (responseJSON["username"]==localStorage.username))
    //     {
    //       flag = true
    //       console.log(responseJSON["username"])
    //     }
    //     else if ("detail" in responseJSON){
    //       flag = false;
    //     }
    //     else{
    //       flag  = false;
    //     }
    //   })
    // }
    // else {flag = false;}
    return this.authenticated;
  }


}

export default new Auth()
