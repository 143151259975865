import React,{Component} from "react"
import auth from "components/auth.js"
import "./assets/scss/blk-design-system-react.scss";
import "./assets/css/nucleo-icons.css";
import PageHeader from "./components/headers.js"
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

class LandingPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      passWord : ""
    }
    this.handleFormChange=this.handleFormChange.bind(this)
  }

  handleFormChange (event) {

    this.setState({
      [event.target.name]:event.target.value
    })
  }

render(){
  return (
  <div className="wrapper">

  <div className="page-header">

    <div className="content">
    <h3 className="title"> Welcome Back </h3>

    <Container>

    <Row className="justify-content-md-center">
    <Col lg = "3">
    <Card>
    <CardBody>


    <form>
    <FormGroup>
    <Input
      type ="password"
      value = {this.state.passWord}
      name = "passWord"
      placeholder = "Password"
      onChange={this.handleFormChange}
    />
    </FormGroup>
   <Button color = "warning" type = "submit"
   size = "sm"
   onClick = {
     ()=>{
       console.log(this.state.passWord)
       if (this.state.passWord === "luojun123"){
         auth.login(()=>{
           this.props.history.push("/app");
         })
       }
       else{
         auth.logout(()=>{
           this.props.history.push("/");
         })
       }

     }

   }

   >
  <i className="tim-icons icon-spaceship" /> Enter
   </Button>


  </form>

</CardBody>
</Card>
</Col>
</Row>
</Container>
  </div>
  </div>
  </div>



  )
}



}


// function LandingPage (props){
//   return <div>
//   <h1> Landing </h1>
//   <button onClick = {
//     ()=>{
//       auth.login(()=>{
//         props.history.push("/app"); });
//     } }
//   >
//     Enter with Password
//
//   </button>
//
//   </div>
// }

export default LandingPage
