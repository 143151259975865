import React from "react";
import { Link,withRouter } from "react-router-dom";
import auth from "./auth.js"
import UserAuth from "./userAuth.js"
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import urlHead from "./backapi.js"




class SucNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      flag:UserAuth.isAuthenticated()
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeColor);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeColor);
  }

  changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      this.setState({
        color: "bg-info"
      });
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  onCollapseExiting = () => {
    this.setState({
      collapseOut: "collapsing-out"
    });
  };
  onCollapseExited = () => {
    this.setState({
      collapseOut: ""
    });
  };

  render() {



    for (let key in localStorage){
      console.log(key," in local",localStorage[key])
    }

    return (
      <Navbar
        className={"fixed-top " + this.state.color}
        color-on-scroll="100"
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              to="/app"
              id="navbar-brand"
              tag={Link}
            >
              <span>Internal Use• </span>
              Performance Analytics System
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              提高效率是提高核心竞争力的关键
            </UncontrolledTooltip>
            <button
              aria-expanded={this.state.collapseOpen}
              className="navbar-toggler navbar-toggler"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse
            className={"justify-content-end " + this.state.collapseOut}
            navbar
            isOpen={this.state.collapseOpen}
            onExiting={this.onCollapseExiting}
            onExited={this.onCollapseExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <a>
                    欧皇工会
                  </a>
                </Col>
                <Col className="collapse-close text-right" xs="6">
                  <button
                    aria-expanded={this.state.collapseOpen}
                    className="navbar-toggler"
                    onClick={this.toggleCollapse}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </button>
                </Col>
              </Row>

              <Row>
              <Button color="warning">Warning</Button>
              </Row>

            </div>
            <Nav navbar>


              <NavItem>
                <Button
                  className="nav-link d-none d-lg-block"
                  color="info"
                  //target="_blank"
                  //href="/login"
                  onClick={
                    async ()=>{
                      var flag
                      flag = false
                      if (localStorage.authToken)
                      {
                        var targetUrl = urlHead+"users/me/"

                        await fetch(targetUrl,
                        {
                          method:'GET',
                          headers:{
                            'Content-Type':'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Token '+localStorage.authToken
                          }

                        })
                        .then(response=>response.json())
                        .then(responseJSON=>{
                          //console.log("jimmy stupid again")
                          if ("id" in responseJSON && (responseJSON["username"]==localStorage.username))
                          {

                            flag = true
                          //  console.log(responseJSON["username"],"successful verified jimmy")

                            localStorage.setItem('loginStatus',"true")
                        //    console.log("record store successfully")
                            for (let key in localStorage){
                              console.log(key," in current local",localStorage[key])
                            }
                          }
                          else if ("detail" in responseJSON){
                            localStorage.setItem('loginStatus',"false")
                            flag = false
                          }
                          else{
                            localStorage.setItem('loginStatus',"false")
                            console.log(localStorage.getItem('username'))

                            flag = false
                          }
                        })
                      }
                      else
                      {
                        flag = false
                      }

                      if (flag)//localStorage.loginStatus && localStorage.loginStatus=="true")
                      {
                        auth.login(()=>{
                          this.props.history.push("/dashboard");});
                      }
                      else
                      {
                        auth.login(()=>{
                          this.props.history.push("/app");});
                      }
                    } }
                >
                  <i className="tim-icons icon-chart-bar-32" /> Dashboard
                </Button>
              </NavItem>

              <NavItem>


                <Button
                className="nav-link d-none d-lg-block"
                color = "success"
                onClick={
                  async ()=>{
                    var flag
                    flag = false
                    if (localStorage.authToken)
                    {
                      var targetUrl = urlHead+"users/me/"

                      await fetch(targetUrl,
                      {
                        method:'GET',
                        headers:{
                          'Content-Type':'application/json',
                          'Accept': 'application/json',
                          'Authorization': 'Token '+localStorage.authToken
                        }

                      })
                      .then(response=>response.json())
                      .then(responseJSON=>{
                    //    console.log("jimmy stupid again")
                        if ("id" in responseJSON && (responseJSON["username"]==localStorage.username))
                        {

                          flag = true
                          console.log(responseJSON["username"],"successful verified jimmy")

                          localStorage.setItem('loginStatus',"true")
                          console.log("record store successfully")
                          for (let key in localStorage){
                            console.log(key," in current local",localStorage[key])
                          }
                        }
                        else if ("detail" in responseJSON){
                          localStorage.setItem('loginStatus',"false")
                          flag = false
                        }
                        else{
                          localStorage.setItem('loginStatus',"false")
                          console.log(localStorage.getItem('username'))

                          flag = false
                        }
                      })
                    }
                    else
                    {
                      flag = false
                    }

                    if (flag)//localStorage.loginStatus && localStorage.loginStatus=="true")
                    {
                      auth.login(()=>{
                        this.props.history.push("/profile");});
                    }
                    else
                    {
                      auth.login(()=>{
                        this.props.history.push("/login");});
                    }

                }
              }
                >
                  <i className="tim-icons icon-single-02" />
                  Welcome Back!
                </Button>


              </NavItem>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(SucNavbar);
