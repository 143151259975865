import {
  configureStore,
  createSlice
} from "@reduxjs/toolkit";

// const erTrue = createAction("ER_TRUE");
// const erFalse = createAction("ER_FALSE");

const msgState = {
  trigState: false,
  sucState: false,
  infoState: ''

};

const msgSlice = createSlice({
  name:"msg",
  initialState:msgState,
  reducers:{
      erTrue: (state,action)=>{
        state.trigState = true
        state.sucState = true
        state.infoState = action.payload.infoState
      },
      erFalse: (state,action)=>{
        state.trigState = true
        state.sucState = false
        state.infoState = action.payload.infoState
      },
      erClose: (state,action)=>{
        state.trigState = false
        state.sucState = false
        state.infoState = action.payload.infoState
      },


  },


})

const {erTrue,erFalse,erClose} = msgSlice.actions;
const msgReducer = msgSlice.reducer;

const store = configureStore({
  reducer:{
    msg:msgReducer
  }
})

export {store,erTrue,erFalse,erClose};
