import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'

import AddEditForm from './AddEdit.js'

class ModalForm extends Component {
    constructor(props){
      super(props)
      this.state = {
        modal : false
      }
    }

    toggle = () =>{
      this.setState(prevState=>({
        modal:!prevState.modal
      }))
    }

    render(){
      const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>

      const label = this.props.buttonLabel

      let button = ''
      let title = ''
      if(label === 'Edit'){
        button = <Button
                  className = "btn-round btn-icon"
                  size="sm"
                  color="info"
                  onClick={this.toggle}
                  ><i className="tim-icons icon-pencil"/>
                </Button>
        title = 'Edit Item'
      }
      else{
        button = <Button
                  size = "sm"
                  color="success"
                  onClick={this.toggle}
                  style={{float: "left", marginRight:"10px"}}>{label}
                </Button>
        title = 'Add New Item'
      }

      return (
        <div>
        {button}
        <Modal
        style={{backgroundColor: '#32325d'}}

        isOpen={this.state.modal}
        toggle={this.toggle}
        className={this.props.className}>
          <ModalHeader
          toggle={this.toggle}
          close={closeBtn}
          style={{backgroundColor: '#32325d'}}
          >
        <p className="text-white">{title}</p>
          </ModalHeader>
          <ModalBody



          style={{backgroundColor: '#32325d'}}
          >
            <AddEditForm
              addItemtoState={this.props.addItemtoState}
              updateState={this.props.updateState}
              toggle={this.toggle}
              item={this.props.item} />
          </ModalBody>
        </Modal>
      </div>
      )

    }

}
export default ModalForm
